import axios from "axios";

export const getItemCost = (docId: string) => {
	return axios.get(`/api/resource/Item Costing/${docId}`);
};

export const updateItemCost = (docId: string, data: any) => {
	return axios.put(`/api/resource/Item Costing/${docId}`, data);
};

export const createManualCostingSheet = (data: any) => {
	return axios.post(`/api/resource/Item Costing`, data);
};
