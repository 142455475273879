import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogOverlay,
} from "@chakra-ui/react";
import React from "react";

const BasicDialog: React.FC<any> = (props) => {
	const cancelRef: any = React.useRef();

	return (
		<AlertDialog
			motionPreset="slideInBottom"
			leastDestructiveRef={cancelRef}
			onClose={props.onClose}
			isOpen={props.isOpen}
			isCentered
			closeOnOverlayClick={false}
			size={
				props.size ?? {
					xs: "xs",
					md: "sm",
				}
			}
		>
			<AlertDialogOverlay />

			<AlertDialogContent>
				<AlertDialogHeader
					color={props?.headerColor}
					textAlign={props?.headerTextAlign || "left"}
				>
					{props.header}
				</AlertDialogHeader>
				<AlertDialogCloseButton />
				{props?.showHeaderBottomBorder && <hr />}
				<AlertDialogBody>{props.content}</AlertDialogBody>
			</AlertDialogContent>
		</AlertDialog>
	);
};

export { BasicDialog };
