import { useDispatch } from "react-redux";
import {
	setPageLoading,
	AppDispatch,
	SET_MESSAGE,
	ERROR_MSG,
} from "../utils/store/store";
import { formatErrorMessage } from "../utils/formatErrorMessage";

// Custom hook: useErrorHandler
export const useErrorHandler = () => {
	const dispatch = useDispatch<AppDispatch>();

	const handleError = (err) => {
		dispatch(
			setPageLoading({
				loading: false,
				loadingText: "",
			})
		);

		dispatch({
			type: SET_MESSAGE,
			payload: { case: ERROR_MSG, data: formatErrorMessage(err) },
		});
	};

	return handleError;
};
