import axios from "axios";

export const frappeMethodService = async (
	method: string,
	params: any,
	type: string
) => {
	switch (type) {
		case "is_allowed":
			return await axios.get(`/api/method/${method}`);
		case "get_user":
			return await axios.get(`/api/method/${method}?search_user=${params}`);
		case "login_as":
			return await axios.get(`/api/method/${method}?user=${params}`);
		case "post_with_params":
			return await axios.post(`/api/method/${method}`, params);
		case "get_with_params":
			return await axios.get(`/api/method/${method}`, params);
		default:
			return await axios.get(`/api/method/${method}`);
	}
};
