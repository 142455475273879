import {
	Input,
	Text,
	Box,
	InputGroup,
	InputRightElement,
	Select,
	InputLeftAddon,
	Checkbox,
	Textarea,
	Flex,
	Tooltip,
	InputLeftElement,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { ErrorMessage, useField } from "formik";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { BsInfoCircle } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../styles/FormStyles.css";
import ReactSelect from "react-select";

export const RenderTextField = ({
	label,
	placeholder,
	disabled,
	isMandatory,
	...props
}: any) => {
	const [field, meta] = useField(props);
	const [show, setShow] = React.useState<any>(true);
	const handleClick = () => setShow(!show);
	// const [value, setValue] = React.useState<any>("");
	useEffect(() => {
		if (props.type === "password") {
			setShow(false);
		}
	}, []);
	return (
		<Box>
			<Flex alignItems="center" justifyContent="flex-start" gap=".5rem">
				<Text
					textStyle="primary.text"
					sx={{ marginBottom: "0.3rem" }}
					fontSize={props.labelsize}
					fontWeight={props.labelweight}
					color={props.labelColor}
				>
					{label} {isMandatory && <span style={{ color: "red" }}>*</span>}
				</Text>
				{props?.infoAvailable && (
					<Tooltip label={props?.infolabel}>
						<span>
							<BsInfoCircle size="1.125rem" />
						</span>
					</Tooltip>
				)}
			</Flex>
			<InputGroup>
				{props.type === "contactNumber" && (
					<InputLeftAddon children={props.left_element} />
				)}
				{props?.left_element && (
					<InputLeftElement>{props?.left_element}</InputLeftElement>
				)}
				<Input
					placeholder={placeholder || label}
					{...field}
					{...props}
					isInvalid={meta.touched && meta.error}
					isReadOnly={disabled}
					fontSize={{ xs: "xs", lg: "sm" }}
					type={props.type === "password" && show ? "text" : props.type}
					variant={props.variant || "filled"}
				/>
				{props.type === "password" && (
					<InputRightElement width="4.5rem">
						{show ? (
							<FaRegEyeSlash onClick={handleClick} color="#fff" />
						) : (
							<FaRegEye onClick={handleClick} color="#fff" />
						)}
					</InputRightElement>
				)}
			</InputGroup>
			<ErrorMessage component="div" name={field.name} className="error" />
		</Box>
	);
};

export const RenderDropDown = ({
	label,
	values,
	placeholder,
	disabled,
	isMandatory,
	defaultValue,
	...props
}: any) => {
	const [field, meta] = useField(props);
	return (
		<Box>
			<Text
				textStyle="primary.text"
				sx={{ marginBottom: "0.3rem" }}
				fontSize={props.labelsize}
				fontWeight={props.labelweight}
			>
				{label} {isMandatory && <span style={{ color: "red" }}>*</span>}
			</Text>
			<Select
				{...field}
				{...props}
				isInvalid={meta.touched && meta.error}
				variant="filled"
				defaultValue={defaultValue}
				disabled={disabled}
			>
				<option value="">Select</option>
				{values?.map((item: any) => {
					return (
						<option value={item} key={item}>
							{item}
						</option>
					);
				})}
			</Select>
			<ErrorMessage component="div" name={field.name} className="error" />
		</Box>
	);
};
export const RenderCheckboxField: React.FC<any> = ({
	label,
	placeholder,
	...props
}) => {
	const [field, meta] = useField(props);
	return (
		<Box>
			<Checkbox {...field} {...props}>
				<Text fontSize={{ xs: "xs", lg: "sm" }} fontWeight={props.labelweight}>
					{label}
				</Text>
			</Checkbox>
			<ErrorMessage component="div" name={field.name} className="error" />
		</Box>
	);
};

export const RenderDropdownField: React.FC<any> = ({
	options,
	label,
	disabled,
	isMandatory,
	value,
	handleDropdownChange,
	...props
}) => {
	const [field, meta] = useField(props);
	const [selected, setSelected] = React.useState("");
	const [allFormValues, setAllFormValues] = React.useState({});

	return (
		<Box>
			<Text
				sx={{ marginBottom: "0.3rem" }}
				fontSize={props.labelsize || "xs"}
				fontWeight={props.labelweight}
			>
				{label} {isMandatory && <span style={{ color: "red" }}>*</span>}
			</Text>
			<Select
				{...field}
				{...props}
				placeholder="Select"
				value={value}
				onChange={handleDropdownChange}
			>
				{options?.map((item: any) => (
					<option key={item}>{item}</option>
				))}
			</Select>
			<ErrorMessage component="div" name={field.name} className="error" />
		</Box>
	);
};

export const RenderCountryStateDropdown = ({
	label,
	values,
	placeholder,
	disabled,
	isMandatory,
	...props
}: any) => {
	const [field, meta] = useField(props);
	return (
		<Box>
			<Text
				textStyle="primary.text"
				sx={{ marginBottom: "0.3rem" }}
				fontSize={props.labelsize}
				fontWeight={props.labelweight}
			>
				{label} {isMandatory && <span style={{ color: "red" }}>*</span>}
			</Text>
			<Select
				{...field}
				{...props}
				isInvalid={meta.touched && meta.error}
				isReadOnly={disabled}
				variant="filled"
				// defaultValue="IN"
				value={props.value}
				onChange={(e) => props?.onChange(e.target.value)}
			>
				<option value="">Select</option>

				{values?.map((item: any) => {
					return (
						<option
							value={item.isoCode ? item.isoCode : item.name}
							key={item.isoCode ? item.isoCode : item.name}
						>
							{item.name}
						</option>
					);
				})}
			</Select>
			<ErrorMessage component="div" name={field.name} className="error" />
		</Box>
	);
};
export const RenderPhoneField: React.FC<any> = ({
	options,
	label,
	disabled,
	isMandatory,
	...props
}) => {
	const [field, meta, helpers] = useField(props.name);
	return (
		<Box>
			<Text
				textStyle="primary.text"
				sx={{ marginBottom: "0.3rem" }}
				fontSize={props.labelsize}
				fontWeight={props.labelweight}
				color={props?.labelColor}
			>
				{label} {isMandatory && <span style={{ color: "red" }}>*</span>}
			</Text>
			<PhoneInput
				isInvalid={meta.touched && meta.error}
				enableSearch={true}
				inputStyle={{
					width: "100%",
				}}
				country="in"
				{...field}
				{...props}
				value={field.value}
				international
				onChange={(value) => {
					helpers.setValue(value);
					if (props.onPhoneChange) {
						props.onPhoneChange(value);
					}
				}}
			/>
			<ErrorMessage component="div" name={field.name} className="error" />
		</Box>
	);
};

export const RenderTextAreaField = ({
	label,
	placeholder,
	disabled,
	isMandatory,
	...props
}: any) => {
	const [field, meta] = useField(props);
	const [show, setShow] = React.useState<any>(true);
	const handleClick = () => setShow(!show);
	useEffect(() => {
		if (props.type === "password") {
			setShow(false);
		}
	}, []);
	return (
		<Box>
			<Text
				textStyle="primary.text"
				sx={{ marginBottom: "0.3rem" }}
				fontSize={props.labelsize}
				fontWeight={props.labelweight}
			>
				{label} {isMandatory && <span style={{ color: "red" }}>*</span>}
			</Text>
			<Textarea
				placeholder={placeholder || label}
				{...field}
				{...props}
				isInvalid={meta.touched && meta.error}
				isReadOnly={disabled}
				fontSize={{ xs: "xs", lg: "sm" }}
			></Textarea>
			<ErrorMessage component="div" name={field.name} className="error" />
		</Box>
	);
};

export const SearchDropdown = ({
	label,
	values,
	value,
	placeholder,
	disabled,
	isMandatory,
	defaultValue,
	onChange,
	...props
}: any) => {
	const [field, meta, helpers] = useField(props.name);
	const selectedOption = values.find(
		(option: any) => option.value === field.value
	);

	return (
		<Box>
			<Text
				textStyle="primary.text"
				sx={{ marginBottom: "0.3rem" }}
				fontSize={props.labelsize}
				fontWeight={props.labelweight}
				color={props.labelColor}
			>
				{label} {isMandatory && <span style={{ color: "red" }}>*</span>}
			</Text>
			<ReactSelect
				{...field}
				{...props}
				options={values}
				value={selectedOption}
				onChange={(selectedOption: any) => {
					helpers.setValue(selectedOption.value); // set the value of the selected option
					onChange({
						target: { name: field.name, value: selectedOption.value },
					}); //Note: call the onChange function in the parent with the value of the selected option
				}}
			/>
			<ErrorMessage component="div" name={field.name} className="error" />
		</Box>
	);
};
