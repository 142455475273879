import { lazy } from "react";

const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const ProfileMain = lazy(() => import("../pages/Profile/ProfileMain"));
const ItemsMain = lazy(() => import("../pages/ItemLibrary/ItemsMain"));
const Login = lazy(() => import("../pages/Auth/Login"));
const Register = lazy(() => import("../pages/Auth/Register"));
const ForgotPassword = lazy(() => import("../pages/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/Auth/ResetPassword"));
const Item = lazy(() => import("../pages/ItemLibrary/Item"));
const LandingPage = lazy(() => import("../pages/LandingPage/LandingPage"));
const MobileRestriction = lazy(() => import("../pages/MobileRestriction"));
const VerifyEmail = lazy(() => import("../pages/Auth/VerifyEmail"));
const ManualCosting = lazy(() => import("../pages/ItemLibrary/ManualCosting"));

const RoutesList: Array<{
	label: string;
	link: string;
	type: "public" | "private" | "landing";
	component: typeof Dashboard;
}> = [
	//access pages
	{
		label: "Landing",
		link: "/",
		type: "landing",
		component: LandingPage,
	},
	{
		label: "mobile",
		link: "/mobile",
		type: "public",
		component: MobileRestriction,
	},
	{
		label: "Login",
		link: "/login/:redirect?",
		type: "public",
		component: Login,
	},
	{
		label: "Register",
		link: "/register",
		type: "public",
		component: Register,
	},
	{
		label: "Register",
		link: "/verifyEmail/:otpuuid?",
		type: "public",
		component: VerifyEmail,
	},
	{
		label: "ForgotPassword",
		link: "/forgotPassword",
		type: "public",
		component: ForgotPassword,
	},
	{
		label: "ResetPassword",
		link: "/resetPassword/:changePasswordKey",
		type: "public",
		component: ResetPassword,
	},
	// {
	// 	label: "ResetPassword",
	// 	link: "/resetPassword/:changePasswordKey",
	// 	type: "public",
	// 	component: ResetPassword,
	// },
	//InstantQuote
	{
		label: "Dashboard",
		link: "/fileupload",
		type: "private",
		component: Dashboard,
	},
	{
		label: "ItemsLibrary",
		link: "/itemlibrary/",
		type: "private",
		component: ItemsMain,
	},
	{
		label: "ProfileMain",
		link: "/myprofile",
		type: "private",
		component: ProfileMain,
	},
	{
		label: "ProfileMain",
		link: "/itemlibrary/item/:itemid?/:showDFM?",
		type: "private",
		component: Item,
	},
	{
		label: "ProfileMain",
		link: "/itemlibrary/item/:itemid?/manualCosting",
		type: "private",
		component: ManualCosting,
	},
];

export default RoutesList;
