import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Table, TableContainer, Tbody, Td, Thead, Tr } from "@chakra-ui/react";

// Reorder function to update the list order based on drag result
const reorder = (list: any, startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

// Table component
export const ReorderableTable = ({ columns, data, onReorder }: any) => {
	const [rows, setRows] = useState(data);

	const handleOnDragEnd = (result: any) => {
		if (!result.destination) return;

		const newRows = reorder(
			rows,
			result.source.index,
			result.destination.index
		);
		setRows(newRows);
		if (onReorder) {
			onReorder(newRows); // Optional: Trigger callback to parent with new order
		}
	};

	return (
		<DragDropContext onDragEnd={handleOnDragEnd}>
			<Droppable droppableId="droppable">
				{(provided: any) => (
					<TableContainer
						border="1px"
						borderColor="#0087EE30"
						borderRadius="1rem"
						{...provided.droppableProps}
						ref={provided.innerRef}
						overflowX="auto"
					>
						<Table variant="simple" size="sm">
							<Thead>
								<Tr>
									{columns.map((column: any, index: number) => (
										<Td
											key={index}
											borderRight={columns?.length === index + 1 ? "" : "1px"}
											borderColor="#0087EE30"
											fontSize=".875rem"
											fontWeight="500"
											position={
												index < 3 || columns?.length === index + 1
													? "sticky"
													: "relative"
											} // Make first 3 columns sticky
											left={
												index === 0
													? 0
													: index === 1
													? "72px"
													: index === 2
													? "170px"
													: "unset"
											} // Set left positioning for sticky columns
											right={columns?.length === index + 1 ? 0 : ""}
											zIndex={index < 3 ? 1 : "auto"}
											bg="#e0f2ff"
										>
											{column.header}
										</Td>
									))}
								</Tr>
							</Thead>
							<Tbody>
								{rows.map((row: any, index: number) => (
									<Draggable
										key={row.id}
										draggableId={row.id.toString()}
										index={index}
									>
										{(provided: any) => (
											<Tr ref={provided.innerRef} {...provided.draggableProps}>
												{columns.map((column: any, colIndex: number) => (
													<Td
														key={colIndex}
														fontSize=".875rem"
														borderRight={
															columns?.length === colIndex + 1
																? ""
																: "1px solid #0087EE30"
														}
														borderBottom={
															index === row?.length - 1
																? ""
																: "1px solid #0087EE30"
														}
														position={
															colIndex < 3 || columns?.length === colIndex + 1
																? "sticky"
																: "relative"
														} // Make first 3, and last 1 columns sticky
														left={
															colIndex === 0
																? 0
																: colIndex === 1
																? "72px"
																: colIndex === 2
																? "170px"
																: "unset"
														} // Set left positioning for sticky columns
														right={columns?.length === colIndex + 1 ? 0 : ""}
														zIndex={colIndex < 3 ? 1 : "auto"}
														bg="#fff"
														{...(colIndex === 0
															? provided.dragHandleProps
															: {})}
													>
														{column.Cell
															? column.Cell({
																	value: row[column.accessor],
																	row, // Pass the entire row data to the Cell component
															  })
															: row[column.accessor]}
													</Td>
												))}
											</Tr>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</Tbody>
						</Table>
					</TableContainer>
				)}
			</Droppable>
		</DragDropContext>
	);
};
